import React, { FC } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  useSettings,
  useStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Divider, Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';

import { st, classes } from './CourseAvailability.st.css';
import { classes as serviceClasses } from '../ServiceInfo.st.css';
import settingsParams from '../../../../../settingsParams';
import { DataHooks } from '../consts';
import stylesParams from '../../../../../stylesParams';

interface Props {
  numberOfSpotsLeft?: number;
  checkDivider?: boolean;
}

export const CourseAvailability: FC<Props> = ({
  numberOfSpotsLeft,
  checkDivider,
}) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const isCourseAvailabilityVisible = settings.get(
    settingsParams.isCourseAvailabilityVisible,
  );
  if (!isCourseAvailabilityVisible || numberOfSpotsLeft === undefined) {
    return null;
  }
  const withDivider =
    isCourseAvailabilityVisible &&
    checkDivider &&
    settings.get(settingsParams.isServiceDividerVisible);
  const customAvailabilityText = settings.get(
    settingsParams.courseAvailabilityText,
  );
  const courseAvailability =
    numberOfSpotsLeft <= 0
      ? settings.get(settingsParams.courseNoAvailabilityText) ||
        t('service.schedule.course.fully-booked')
      : customAvailabilityText
      ? `${numberOfSpotsLeft} ${customAvailabilityText}`
      : t('service.schedule.course.number-of-spots-left', {
          numberOfSpotsLeft,
        });
  return (
    <>
      {withDivider ? (
        <Divider
          data-hook={DataHooks.DIVIDER2}
          className={serviceClasses.divider}
        />
      ) : null}
      <Text
        data-hook={DataHooks.COURSE_AVAILABILITY}
        className={st(classes.root, {
          isMobile,
        })}
        typography={TYPOGRAPHY.listText}
        tagName={styles.get(stylesParams.courseAvailabilityFont).htmlTag}
      >
        {courseAvailability}
      </Text>
    </>
  );
};
