import { PriceDomain, PriceDto, WeekDay } from '@wix/bookings-uou-domain';
import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { BusinessInfo as LegacyBusinessInfo } from '../../../legacy/types';
import { getDefaultTimezone } from '../timezone/timezone';

export const getOfferedDays = (service: CatalogServiceDto, t: Function) => {
  const daysOrder =
    t('first-day-of-week') === t('week-day.sun')
      ? [
          WeekDay.SUNDAY,
          WeekDay.MONDAY,
          WeekDay.TUESDAY,
          WeekDay.WEDNESDAY,
          WeekDay.THURSDAY,
          WeekDay.FRIDAY,
          WeekDay.SATURDAY,
        ]
      : [
          WeekDay.MONDAY,
          WeekDay.TUESDAY,
          WeekDay.WEDNESDAY,
          WeekDay.THURSDAY,
          WeekDay.FRIDAY,
          WeekDay.SATURDAY,
          WeekDay.SUNDAY,
        ];

  let offeringDays = service.scheduleHeader.days;
  if (service.scheduleHeader.daysDetails) {
    offeringDays = service.scheduleHeader.daysDetails
      .filter((dayDetails) => {
        const shouldDisplayDay =
          !dayDetails.expirationDate ||
          new Date(dayDetails.expirationDate).getTime() > new Date().getTime();
        if (shouldDisplayDay) {
          return dayDetails;
        }
      })
      .map((dayDetails) => dayDetails.day);
  }

  if (offeringDays!.length === 7) {
    return t('working-hours.all-week');
  }
  const weekDayKeyPrefix: string =
    offeringDays!.length <= 2 ? 'long-week-day.' : 'short-week-day.';
  return daysOrder
    .filter((day) => offeringDays!.indexOf(day) > -1)
    .map((day) => t(weekDayKeyPrefix + day))
    .join(', ');
};

export const getFormattedStartDate = ({
  service,
  language,
  t,
  businessInfo,
}: {
  service: CatalogServiceDto;
  language: string;
  t: Function;
  businessInfo: LegacyBusinessInfo;
}) => {
  if (service.scheduleHeader.startDateAsUTC) {
    try {
      const startDate = new Date(service.scheduleHeader.startDateAsUTC);

      const isDateInCurrentYear = (date: Date) => {
        const now = new Date();
        return now.getFullYear() === date.getFullYear();
      };

      const isDateInThePast = (date: Date) => {
        const now = new Date();
        return date.getTime() < now.getTime();
      };

      const timeZone = getDefaultTimezone(businessInfo);
      const dateFormat = isDateInCurrentYear(startDate)
        ? {
            month: 'short',
            day: 'numeric',
            timeZone,
          }
        : {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone,
          };

      const formattedStartDate = new Intl.DateTimeFormat(
        language,
        dateFormat,
      ).format(startDate);

      return isDateInThePast(startDate)
        ? `${t(
            'service.schedule.course.start.date.passed',
          )} ${formattedStartDate}`
        : `${t(
            'service.schedule.course.start.date.future',
          )} ${formattedStartDate}`;
    } catch (e) {
      return '';
    }
  }
  return '';
};

export const getPrice = ({
  service,
  language,
  locale,
}: {
  service: CatalogServiceDto;
  language: string;
  locale?: string;
}): {
  displayedPrice: string;
  srOnlyPrice: string;
} => {
  const priceDto: PriceDto = {
    ...service.payment,
    displayTextForPlan: service.pricingPlanInfo
      ? service.pricingPlanInfo.displayText
      : '',
  };
  const priceDomain = new PriceDomain(
    priceDto,
    service.offeredAs,
    language,
    locale,
    true,
  );
  return {
    displayedPrice: priceDomain.text,
    srOnlyPrice: priceDomain.ariaLabel,
  };
};
